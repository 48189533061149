import React from 'react'

interface Props {
  className?: string
}

const WhoWeAre = ({ className = '' }: Props) => {
  return (
    <section className={`py-16 md:py-24 px-4 sm:px-6 lg:px-8 ${className}`}>
      <div className="max-w-7xl mx-auto">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Image Section */}
          <div className="relative overflow-hidden shadow-xl">
            <img
              src="/images/team-business-people-collaborating-plan-financial-strategy-doing-teamwork-create-sales-report-laptop-office-employees-working-project-strategy-analyze-career-growth 1.svg"
              alt="Team collaborating on business strategy"
              className="w-full h-auto object-cover"
            />
          </div>

          {/* Content Section */}
          <div className="space-y-6">
            {/* Heading with decorative element */}
            <div className="relative inline-block">
              <h2 className="text-4xl md:text-5xl font-normal text-black font-aldrich relative z-10 ">
                Who we are
              </h2>
              <img
                src="/images/who-we-are-image-text.svg"
                alt=""
                className="absolute -top-5 -right-5 w-5 md:w-7 z-0"
                aria-hidden="true"
              />
            </div>

            {/* Subheading */}
            <span className="block text-xl md:text-2xl font-semibold text-gray-800 mt-4 font-lato">
              We build digital solutions that drives innovation and social
              impact{' '}
            </span>

            {/* Main text */}
            <p className="text-gray-600 text-lg leading-relaxed font-lato">
              At Blouza Technologies, we are experts dedicated to providing
              innovative solutions for business processes automation to drive
              efficiency and revenue maximisation. Our experience in both Public
              and Private sectors gives us an edge as we leverage technology to
              build cutting-edge ERP and revenue optimisation solutions. Our
              e-governance and revenue administration solutions have helped
              drive growth in some of the best performing revenue administration
              agencies in the country while setting the pace for revenue
              solutions in the industry.
            </p>

            {/* Additional content container for potential future expansion */}
            <div className="mt-8 space-y-4">
              {/* Stats or additional info can go here */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoWeAre
